<template>
    <ListHeader
        @input="onInput"
        @change="onSort"
    ></ListHeader>
    <div class="layout-list-content">
        <ul class="layout-list nopadding nomargin">
            <UserListElement 
                v-for="user in users" :key= "user.id" 
                :user="user" 
                :selectedId="selectedId">
            </UserListElement>
        </ul>
    </div>
    <Pagination
       :pagination="pagination"
       @page-change="onPageChange"
    ></Pagination>
</template>
<script>
import {computed} from 'vue'
import {useStore} from 'vuex'
import UserListElement from '@/components/user/UserListElement'
import ListHeader from '@/components/ListHeader'
import Pagination from '@/components/Pagination'
export default {
    props:{
        users:{
            type:Array,
            default:()=>[]
        },
        selectedId:{
            type:String,
            default:''
        },
    },
    components:{
        UserListElement,
        ListHeader,
        Pagination
    },
    setup(){
        const store = useStore()
        
        const onInput = (filter)=>{
            store.dispatch('user/filter', filter) 
        }
        const onSort =(sorting)=>{        
            store.dispatch('user/sort', sorting)
        }
        const pagination = computed(()=>{
            return store.getters['user/pagination']
        })
        const onPageChange = async (page)=>{
           
            await store.dispatch('user/users', {PageNumber:page})
        }
        return{
            onInput,          
            onSort,
            pagination,
            onPageChange
        }
    }
}
</script>