<template>
    <div class="layout-list-header">
        <span class="layout-list-header-title bebas f20 pl16">Utenti</span>
        <a href="#" class="btn-new" @click.prevent="showCreateUser">+</a>
    </div>
    <user-roles-list :filterPath="'user/filter'"></user-roles-list>
    <transition name="downup">
        <create-user-buttons 
        v-if="createUserVisible"
        @close-panel="hideCreateUser"
        @create-user="createUser"
    ></create-user-buttons>
    </transition>
    <user-creator-wizard v-if="showPanel" :defaultRole="creatingUser.role" @close-panel="hideCreateUser"></user-creator-wizard>
    
</template>
<script>
import {ref} from 'vue'
import UserCreatorWizard from '@/components/user/UserCreatorWizard'
import CreateUserButtons from '@/components/user/CreateUserButtons'
import UserRolesList from '@/components/user/UserRolesList'
export default {
    components:{
        CreateUserButtons,
        UserRolesList,
        UserCreatorWizard
    },
    setup(){
        //const store = useStore()
        const createUserVisible = ref(false)
        const showPanel = ref(false)
        const creatingUser = ref(false)
        
        const showCreateUser = ()=>{
            createUserVisible.value = true
        }
        const hideCreateUser = ()=>{
            createUserVisible.value = false
            showPanel.value=false
        }
        const createUser = async(user)=>{
            showPanel.value=true
            creatingUser.value=user
        }



        return{
            showCreateUser,
            hideCreateUser,
            creatingUser,
            createUserVisible,
            createUser,
            showPanel
        }
    }
}
</script>
