<template>
    <div class="flex-container flex-col">
        <div class="flex-container">
            
            <div class="flex-grow-1 pr16">
                <text-input
                    :label="'username'"
                    :value="wizardData.userName || null"
                    :editing="true"
                    :placeholder="'inserisci nome Utente'"
                    :name="'name'"
                    @input="onNameInput"
                ></text-input>
               
                <email-input 
                    placeholder="email" 
                    label="email" 
                    :email="''" 
                    :name="'email'"
                    :editing="true"
                    :value="wizardData.email || null"
                    @input="onEmailInput"
                ></email-input>
               
                
               
                <SelectInput
                    v-if="showRoleSelect"
                    :label="'Role'"
                    :name="'role'"
                    :id="'role'"
                    :editing="true"
                    :value="''"
                    :options="roles.map(r=>r.name)"
                    :className="'flex-container flex-col pb16'"
                    @select-change="roleSelected"
                    
                ></SelectInput>               
            </div>
            <FileUpload
                :editing="true"
                :fileurl ="''"
                @image-selected="onImageSelected"
            ></FileUpload>
        </div>
    </div>
</template>
<script>
import {ref, inject, computed, reactive} from 'vue'
//import useVuelidate from "@vuelidate/core";

//import { required, email, minLength } from "@vuelidate/validators";
//import ErrorMessage from '@/components/formelements/ErrorMessage'
import {Roles} from '../../../utils/RolesAndPolicies'
import FileUpload from '@/components/FileUpload'

import SelectInput from '@/components/formelements/SelectInput'
import TextInput from '@/components/formelements/TextInput'
import EmailInput from '@/components/formelements/EmailInput'
export default {
    emits:['vuel-error','vuel-valid','role-select', 'image-selected'],
    props:{
        defaultRole:{
            type:String
        },
        wizardData:{
            type:Object
        }
    },
    components:{
       // ErrorMessage,
        FileUpload,
        SelectInput,
        TextInput,
        EmailInput
    },
    setup(props, context){
        
        
        const me = inject('me')
       
        
        const userRole = ref(props.defaultRole);
        
        const currentRole = ref(props.defaultRole)
       
        const baseData = reactive({
            userName:props.wizardData.userName,
            uemail:props.wizardData.email,
            role:props.defaultRole
        })


        const showRoleSelect = computed(()=>{
            switch(me.value.roles[0]){
                case 'Superuser':
                case 'Admin':
                    return currentRole.value !== 'Admin' && currentRole.value !== 'Advertiser'
                    
                case 'ConcessionaireAdmin':
                    if(props.defaultRole === 'ConcessionaireUser'){
                        return false
                    }
                    return true
                case 'PublisherAdmin':
                    return false
                default:
                    return null
                    
                    
            }

        })
        
       const hasValidData = ()=> {
            return baseData.uemail  
                    && baseData.uemail !== ''
                    && baseData.userName
                    && baseData.userName !== ''
                    && baseData.role !== ''
                    && baseData.role !== 'Publisher'
                    && baseData.role !== 'Concessionaire'
        }
        
        
        const roleSelected =(event)=>{

            context.emit('role-select', event)
            currentRole.value = event
            baseData.role = event
            if (hasValidData()){
                 context.emit('vuel-valid',baseData)
            }

        }

        const roles = computed(()=> {
            let toReturn
            switch(me.value.roles[0]){
                case 'Superuser':
                case 'Admin':
                    toReturn = Roles.filter(r => r.name !== 'Superuser' && r.name.startsWith(props.defaultRole))
                    break;
                case 'ConcessionaireAdmin':
                    toReturn = Roles.filter(r =>  r.name.startsWith('Publisher'))
                    break;
                case 'PublisherAdmin':
                    toReturn = ['PublisherUser']
                    break;
                default:
                    toReturn =[]
                    break;
            }

            if(props.wizardData.role){
                const index = toReturn.findIndex(r=>r.name === props.wizardData.role)
                if (index > -1) {
                    toReturn.splice(index, 1);
                }
            }
            return toReturn
            
        })
        
        const onImageSelected =(files)=>{
            context.emit('image-selected', files[0])
        }

        
        const onNameInput = ({text}) =>{
         
             baseData.userName = text
            
             if (hasValidData()){
                 context.emit('vuel-valid',baseData)
            }
        } 
        const onEmailInput = ({text}) =>{
            
           
            if(text)
                baseData.uemail = text
         
            if (hasValidData()){
                 context.emit('vuel-valid',baseData)
            }
        } 
        return {
            userRole,
            onEmailInput,
            currentRole,
            roles,
            roleSelected,
            showRoleSelect,
            onImageSelected,
            onNameInput
        }
    }
}
</script>
