<template>   
        <ResizableColumnContainer v-if="canManageUsers" :maxCol="4" @total-size="onTotalSize">
                <resizable-column 
                    :columnIndex="0" 
                    @column-resize="usersColumnResize"
                    ref="tools"
                    :style="toolStyle"
                >
                    <users-tools></users-tools>
                    
                </resizable-column>
                <resizable-column 
                    :columnIndex="1" 
                    @column-resize="usersColumnResize" 
                    ref="list" 
                    :style="listStyle"
                >
                    <UserList :users="users" :selectedId="route.params.id ? route.params.id : ''"></UserList>
                </resizable-column>
                <resizable-column 
                    :columnIndex="2" 
                    :className="`flex-container flex-col layout-content w100`" 
                    @column-resize="usersColumnResize" 
                    ref="card" 
                    :style="cardStyle"
                >
                   <UserCard v-if="user" :selectedUser="user" :key="user.id"></UserCard>
                   <ColumnPlaceHolder v-else></ColumnPlaceHolder>  
                </resizable-column> 
           
        </ResizableColumnContainer>
        <p v-else>
            il ruolo che stai impersonando non può vedere questa pagina
        </p>
</template>

<script>
import { useStore } from 'vuex'
import { useRoute, onBeforeRouteLeave } from 'vue-router'
import {onMounted, computed, ref } from 'vue'
import {useCapabilities} from '../utils/Capabilities'
import ResizableColumn from '@/components/ResizableColumn'
import ResizableColumnContainer from '@/components/ResizableColumnContainer'
import UsersTools from '@/components/user/UsersTools'
import UserList from '@/components/user/UserList'
import UserCard from '@/components/user/UserCard'
import ColumnPlaceHolder from '@/components/ColumnPlaceHolder'
import {apiUserFunction} from '@/api/users'
import { onBeforeRouteUpdate } from 'vue-router'
import {
    tools,
    toolStyle,
    card,
    cardStyle,
    list,
    listStyle,
    onTotalSize,
    totalSize,
    usersColumnResize,
} from '@/utils/ColumnsResizer'
export default {
    components:{
        ResizableColumn,
        ResizableColumnContainer,
        UsersTools,
        UserCard,
        UserList,
        ColumnPlaceHolder
    },
    setup(){
        const {canManageUsers, /* canManageScopes */} = useCapabilities()
        const store = useStore()
        const route = useRoute()
        const users = computed(() => {
          return store.getters['user/filtered'] ? store.getters['user/filtered'] : store.getters['user/paged']
        })
        
       /*  const selectedUser = computed(() => {
          return store.getters['user/users'].find(c => c.id === route.params.id) || null
        }) */

       /*  const loadNext = async()=>{
            const pagination = store.getters['user/pagination']
            await store.dispatch('user/users', {PageNumber:pagination.CurrentPage+1})
            if(!selectedUser.value && route.params?.id){
                await loadNext()
            }
        } */

        const user = ref(null)

        onMounted(async()=>{
            if(route.params?.id){
                user.value = await apiUserFunction.show(route.params.id)
            } else {
                user.value = null
            }

        })

        
        onBeforeRouteLeave(async(to)=>{
            if(canManageUsers.value){
                const cardWidth = card?.value?.$el ? card.value.$el.getBoundingClientRect().width +'px' : '70%'
                try {
                    store.commit('user/savecolumnsizes',{
                    toolsW:tools.value.$el.getBoundingClientRect().width+'px',
                    listW:list.value.$el.getBoundingClientRect().width+'px',
                    cardW:cardWidth,
                })
                } catch (error) {
                     console.log('could not save columns sizes')
                }
                
            }
            if (to.params.id ) {
                user.value = await apiUserFunction.show(to.params.id)
            }else {
                user.value = null
            }
            
        })
        
        onBeforeRouteUpdate(async (to, from) => {
            if (to.params.id !== from.params.id) {
                user.value = await apiUserFunction.show(to.params.id)
            }else {
                user.value = null
            }
        })
        return {
            users,
            user,
            canManageUsers,
            /* selectedUser, */
            usersColumnResize,
            tools,
            toolStyle,
            list,
            listStyle,
            card,
            cardStyle,
            onTotalSize,
            totalSize,
            route
        }
    }
}
</script>

