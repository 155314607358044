<template>
    <div class="flex-container flex-col">
        <div  class="flex-container flex-col mb24" >
            <AjaxSearchSelect
                label="Seleziona concessionaria"
                :feedUrl="ApiEndPoints.USER_SCOPE"
                :params="{discriminator:USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR}"
                @select="concessionaireSelected"
                :key="'ajax-search-'+ keyCount"
                :selected="concessionaire"
            >
            </AjaxSearchSelect>
            <p>Oppure</p>
            <label class="flex-container flex-col pb8">
                <div class="flex-container heading-and-error">
                    <h3 class="bebas nomargin">Crea nuova concessionaria</h3>
                </div>
                <div class="flex-container">
                    <input 
                        required 
                        v-model="newConcessionaireName" 
                        type="text" 
                        name="concessionaireName" 
                        placeholder="Inserisci il nome della concessionaria"
                        class="noborder nopadding pl8 w100 pr8"
                    />
                    <input 
                        :disabled="newConcessionaireName.length < 3" 
                        type="submit" 
                        value="crea" 
                        class="noborder nopadding bebas pl24 pr24 small-btn" 
                        @click.prevent="createNewConcessionaire"
                    >
                </div>
            </label>
        </div>
    </div>
</template>
<script>
import {computed, ref} from 'vue'
import {useStore} from 'vuex'
import { USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR } from '@/utils/RolesAndPolicies'
import {ApiEndPoints} from '@/api/Endpoints'
import AjaxSearchSelect from '@/components/formelements/AjaxSearchSelect'



export default {
    emits:['concessionaire-select', 'userscope-created'],
    components:{
        AjaxSearchSelect
    },
    props:{
        wizardData:{
            type:Object
        }
    },
    setup(props, context ){
        const store = useStore()
        const concessionaire = ref(props.wizardData.concessionaire)
        if(concessionaire.value){
            context.emit('concessionaire-select', concessionaire)
        }
        const concessionaires = computed(()=>{
           
            if(!props.wizardData.concessionaire) {
                return store.getters['userscope/scopes'].filter(scope => scope.discriminator === USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR )
            } else {
                return store.getters['userscope/scopes'].filter(scope => scope.discriminator === USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR && scope.id !== props.wizardData.concessionaire.id)
            }
            
        })

        const concessionaireOptions = ref([])

        const concessionaireSelected =(event)=>{
            //concessionaire.value = concessionaires.value.find(c=>c.id === parseInt(event.target.value))
            //context.emit('concessionaire-select', concessionaire)
            context.emit('concessionaire-select', event)
            
        } 
        const newConcessionaireName = ref('')


        const createNewConcessionaire = async () => {
            
            //await store.dispatch('user/storeNewUserData', props.wizardData)
            const response = await store.dispatch('userscope/createUserScope', {
                name: newConcessionaireName, 
                discriminator: USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR
            })
            concessionaire.value = response
            context.emit('userscope-created', concessionaire)
            keyCount.value++
        }
        const keyCount = ref(0)
        return{
            concessionaire,
            concessionaires,
            concessionaireSelected,
            newConcessionaireName,
            createNewConcessionaire,
            concessionaireOptions,
            ApiEndPoints,
            USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR,
            keyCount
        }
    }
}
</script>