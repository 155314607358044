<template>
    <div class="create-user-panel" ref="panel">
        <div class="layout-list-header">
                <span 
                    class="layout-list-header-title bebas f20 pl16"
                >
                    crea nuovo utente
                </span>
                <a 
                    class="btn-new btn-chiudi" 
                    @click.prevent="close"
                >
                    x
                </a>
            </div>
        <div class="layout-list-content">
            <ul class="layout-list nopadding nomargin">
                <li 
                    v-for="(role, index) in availableRoles" 
                    :key="role+'-'+index" 
                    class="layout-list-item"
                >
                    <a 
                        href="#" 
                        class="layout-list-item-btn pl16 pr24" 
                        @click.prevent="create(role)"
                    >
                        Crea utente {{role}}
                    </a> 
                </li>
            </ul>
        </div>
    </div>
</template>
<script>


import {computed} from 'vue'
import {useStore} from 'vuex'
import{
  USER_ROLE_SUPER,
  USER_ROLE_ADMIN,
  USER_ROLE_ADVERTISER,
  USER_ROLE_CONCESSIONAIRE_ADMIN,
  USER_ROLE_CONCESSIONAIRE_USER,
  USER_ROLE_PUBLISHER_ADMIN,
  USER_ROLE_PUBLISHER_USER
} from '@/utils/RolesAndPolicies'
//import {useCapabilities} from '@/utils/Capabilities'
export default {
    emits: ['close-panel','create-user'],
    setup(_,context){
        const store = useStore()
        //const { canManageScopes} = useCapabilities()
        const close = ()=>{ 
            context.emit('close-panel')
        }
        const create = async(userType)=>{ 
           
            const newUser = {
                userName:'',
                email:'',
                role:userType
            }
            
            /* if(canManageScopes.value && (userType === 'Publisher' || userType === 'Concessionaire')){
                
            } */
           
            close()
            context.emit('create-user', newUser)
        }
        const availableRoles = computed(()=> {
            let toReturn =[]
            if(store.getters['auth/me'].roles){
                switch(store.getters['auth/me'].roles[0]){
                    case USER_ROLE_SUPER:
                    case USER_ROLE_ADMIN:
                        toReturn = [USER_ROLE_ADMIN,USER_ROLE_ADVERTISER,'Publisher','Concessionaire']
                        break;
                    case USER_ROLE_CONCESSIONAIRE_ADMIN:
                        toReturn = [USER_ROLE_CONCESSIONAIRE_USER, 'Publisher']
                        break;
                    case USER_ROLE_PUBLISHER_ADMIN:
                        toReturn = [USER_ROLE_PUBLISHER_USER]
                        break;
                }
            }
            
            return toReturn
            
        })
        
        return {
            close,
            create,
            availableRoles
        }
    }
}
</script>
