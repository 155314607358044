<template>
    <form class="create flex-container flex-col">
            <div class="panel-header mb24">
                <div class="mini-logo"></div>
                <h2 
                    class="panel-title nomargin bebas"
                >
                    Stai creando un utente {{wizardData.role !== '' ? wizardData.role : defaultRole}}
                </h2>
            </div>
            <user-base-info 
                v-if="wizardData.step === 0" 
                :defaultRole="defaultRole"
                :wizardData ="wizardData" 
                @vuel-error="preventNext=true"
                @vuel-valid="onValidatedData"
                @role-select="onRoleSelected"
                @image-selected="onImageSelected"
            ></user-base-info>
            <concessionaire-info
                v-if="wizardData.step === 1"
                @concessionaire-select="onConcessionaireSelected"
                :wizardData ="wizardData"
                @userscope-created="onConcessionaireCreated"
            >
            </concessionaire-info>

            <publisher-info
                v-if="wizardData.step === 2"
                @publisher-select="onPublisherSelected"
                :wizardData ="wizardData"
                @userscope-created="onPublisherCreated"
            >
            </publisher-info>

            <div class="flex-container ">
                <input 
                    type="reset" 
                    :value="wizardData.step === 0 ? 'annulla' : 'indietro'" 
                    class="w100 noborder nopadding bebas" 
                    @click.prevent="back"
                >
                <input 
                    :disabled="preventNext" 
                    type="submit" 
                    :value="nextButtonText" 
                    class="w100 noborder nopadding bebas" 
                    @click.prevent="next"
                >
            </div>    
        </form>
</template>
<script>


import {reactive, ref, computed, inject} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import UserBaseInfo from '@/components/user/wizard/UserBaseInfo'
import ConcessionaireInfo from '@/components/user/wizard/ConcessionaireInfo'
import PublisherInfo from '@/components/user/wizard/PublisherInfo'
import{
  
  USER_ROLE_CONCESSIONAIRE_ADMIN,
  USER_ROLE_CONCESSIONAIRE_USER,
} from '@/utils/RolesAndPolicies'
import { USER_ROLE_PUBLISHER_ADMIN, USER_ROLE_PUBLISHER_USER } from '../../../utils/RolesAndPolicies'
export default {
    emits: ['close-panel'],
    props:{
        defaultRole:{
            type:String
        }
    },
    components:{
        UserBaseInfo,
        ConcessionaireInfo,
        PublisherInfo
    },
    setup(_,{emit} ){
        const store = useStore()
        const me = inject('me')
        const router = useRouter()
        const wizardData = reactive({
            step:0,
            userName:null,
            email:null,
            role:'',
            concessionaire:null,
            publisher:null
        })

        const onValidatedData =(data)=>{
            const {role, userName, email} = data
            preventNext.value = false
            wizardData.role = role
            wizardData.userName = userName
            wizardData.email = email
        }

        const onRoleSelected = (role) =>{
            wizardData.role = role
        }

        const onConcessionaireSelected = (concessionaire) =>{
            wizardData.concessionaire = concessionaire
            preventNext.value = false
        }  
        const onConcessionaireCreated = (concessionaire) =>{
            wizardData.concessionaire = concessionaire
            next()
        } 
        const onPublisherCreated = (publisher) =>{
            wizardData.publisher = publisher
            preventNext.value = false
        } 

        const onPublisherSelected = (publisher) =>{
           
            wizardData.publisher = publisher
            preventNext.value = false
        } 


        const back = ()=>{
            
            if(wizardData.step === 0){
                abortCreate()
            } else {

                switch(wizardData.step){
                    case 1:
                        wizardData.step--
                       
                        break;
                    case 2:
                        wizardData.step--
                        
                        wizardData.publisher=null
                        
                        break;
                }

                /* if(me.value.roles[0] === USER_ROLE_CONCESSIONAIRE_ADMIN){
                    wizardData.step--
                } */
                preventNext.value = false
            }
        }

        const preventNext = ref(true)

        const next = () =>{
            if(wizardData.step === 1){
                save()
            }else {
                if(wizardData.step < 2){ 
                    wizardData.step++                   
                    if(wizardData.role === USER_ROLE_PUBLISHER_ADMIN || wizardData.role === USER_ROLE_PUBLISHER_USER ){
                        wizardData.step++
                    }
                    preventNext.value = true
                } else {
                    save()
                }
            }
        } 

        const abortCreate = async() => {
             emit('close-panel')
            
        }
        const save = async () => {

            const data = {
                userName:wizardData.userName,
                email:wizardData.email,
                role:wizardData.role
            }
            
            if(wizardData.role === USER_ROLE_CONCESSIONAIRE_ADMIN || wizardData.role === USER_ROLE_CONCESSIONAIRE_USER){
                data.userScopeId = me.value.userScope.id
            }
            if(wizardData.role === USER_ROLE_PUBLISHER_ADMIN || wizardData.role === USER_ROLE_PUBLISHER_USER){
                data.userScopeId = wizardData.publisher.id
            }

            
            try{
                const response = await store.dispatch('user/saveUser', {data:data})
                if(avatar.value){
                    await uploadAvatar(response.id)
                }
               
                await store.dispatch('messages/message', { title:'Utente creato', message:data.userName })
                emit('close-panel')
                router.push('/user/'+ response.id)
            }catch(error){
                console.log(error)
                await store.dispatch('messages/message', { title:'Errore nella creazione utente', message:error.message })
            }
            
            store.commit('user/user_edit', {editing:false})
        }

        const nextButtonText = computed(() =>{
            
            let text = 'next'
            
            if(wizardData.step === 1 ){
                text = 'salva'
            }
            if(wizardData.step === 2 ){
                text = 'salva'  
            }

            return text

         })

        const avatar = ref(null)

        const onImageSelected =(file)=>{
            avatar.value = file
        }
        const uploadAvatar = async(userId) => {
            await store.dispatch('user/upload', {
                avatar:avatar.value, id:userId
            })
        }

        return {
            wizardData,
            abortCreate,
            back,
            next,
            preventNext,
            onValidatedData,
            onConcessionaireSelected,
            onPublisherSelected,
            nextButtonText,
            onConcessionaireCreated,
            onPublisherCreated,
            onRoleSelected,
            onImageSelected
        }

    }
}
</script>