<template>
<teleport to="#overlay">
        <div class="overlay w100 h100 abs">
            <div class="overlay-panel p24 rel">
                <AdminsWizard 
                    v-if="me.roles[0] === USER_ROLE_SUPER ||me.roles[0] === USER_ROLE_ADMIN" 
                    :defaultRole="defaultRole" 
                    @close-panel="$emit('close-panel')"
                ></AdminsWizard>
                <ConcessionairesWizard 
                    v-else-if="me.roles[0] === USER_ROLE_CONCESSIONAIRE_ADMIN || me.roles[0] === USER_ROLE_CONCESSIONAIRE_USER" 
                    :defaultRole="defaultRole"
                    @close-panel="$emit('close-panel')"
                ></ConcessionairesWizard>
                <PublishersWizard 
                    v-else-if="me.roles[0] === USER_ROLE_PUBLISHER_ADMIN" 
                    :defaultRole="defaultRole"
                    @close-panel="$emit('close-panel')"
                ></PublishersWizard>
            </div>
     </div>
      
    </teleport>
</template>
<script>

import { inject} from 'vue'
import AdminsWizard from '@/components/user/wizard/AdminsWizard'
import ConcessionairesWizard from '@/components/user/wizard/ConcessionairesWizard'
import PublishersWizard from '@/components/user/wizard/PublishersWizard'
import{
  USER_ROLE_SUPER,
  USER_ROLE_ADMIN,
  USER_ROLE_ADVERTISER,
  USER_ROLE_CONCESSIONAIRE_ADMIN,
  USER_ROLE_CONCESSIONAIRE_USER,
  USER_ROLE_PUBLISHER_ADMIN,
  USER_ROLE_PUBLISHER_USER
} from '@/utils/RolesAndPolicies'
export default {
    emits:['close-panel'],
    props:{
        defaultRole:{
            type:String
        }
    },
    components:{
        AdminsWizard,
        PublishersWizard,
        ConcessionairesWizard
    },
    setup(){
       
        const me = inject('me')
       

        return {
            me,
            USER_ROLE_SUPER,
            USER_ROLE_ADMIN,
            USER_ROLE_ADVERTISER,
            USER_ROLE_CONCESSIONAIRE_ADMIN,
            USER_ROLE_CONCESSIONAIRE_USER,
            USER_ROLE_PUBLISHER_ADMIN,
            USER_ROLE_PUBLISHER_USER
        }

    }
}
</script>